import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '@/store/context';
import { CaseStudyData, CaseStudyProps } from '@/mock/SiteData';
import { CopyCentered } from '@/components/shared/copy-centered';
import { Separator } from '@/components/ui/separator';
import { TechnologiesList } from '@/components/shared/technologies-list';
import { TitleCopy } from '@/components/shared/title-copy';
import { Review } from '@/components/shared/review';
import { ImageGallery } from '@/components/shared/image-gallery';
import { CaseStudyBanner } from '@/components/shared/case-study-banner';
import { LeftRightCopy } from '@/components/shared/left-right-copy';
import { cn } from '@/lib/utils';
import SEO from '@/components/shared/SearchEngineOptimizations/SearchEngineOptimizations';
import useTriggerLightTheme from '@/hooks/useTriggerLightTheme';
import NotFoundPage from '@/pages/404';
import ReactLogo from '@/assets/images/technologies/react.inline.svg';
import NodeJsLogo from '@/assets/images/technologies/nodejs.inline.svg';
import TypescriptLogo from '@/assets/images/technologies/typescript.inline.svg';
import ClutchLogo from '@/assets/images/clutch.inline.svg';
import useScrollAnimation from '@/hooks/useScrollAnimation';
import useStickyScrollAnimation from '@/hooks/useStickyScrollAnimation';
import { SectionHeader } from '@/components/shared/section-header';
import { AnimatedTagCopy } from '@/components/shared/animated-tag-copy';
import { FullScreenSlider } from '@/components/shared/full-screen-slider';
import { useTransitionAnimation } from '@/hooks/useTransitionAnimation';

const ANIMATED_CLASSNAME = 'animated fadeInUp';

const caseStudy: CaseStudyProps | undefined = CaseStudyData.find((m) => m.id === 'anthoscapital');

const AnthosCapital = () => {
  const { setCurrentPage } = useContext(AppContext);

  const { tlState, defaultAnimationOffset } = useTransitionAnimation();

  const projectWhiteSectionRef = useRef<HTMLDivElement>(null);

  useTriggerLightTheme(
    projectWhiteSectionRef.current,
    defaultAnimationOffset,
    defaultAnimationOffset,
    tlState,
    0.5,
  );

  // TODO: we'll to think a better approach to avoid calling several times the same webhook

  const copyCenteredRef = useScrollAnimation();

  const technologiesListRef = useScrollAnimation();

  const titleCopyFirst = useScrollAnimation();

  const titleCopySecond = useScrollAnimation();

  const imageGalleryFirst = useScrollAnimation();

  const { elementRef: leftRightCopyFirst, titleRef: leftRightCopyFirstTitle } =
    useStickyScrollAnimation();

  const { elementRef: leftRightCopySecond, titleRef: leftRightCopySecondTitle } =
    useStickyScrollAnimation();

  const { elementRef: leftRightCopyThird, titleRef: leftRightCopyThirdTitle } =
    useStickyScrollAnimation();

  const { elementRef: leftRightCopyFourth, titleRef: leftRightCopyFourthTitle } =
    useStickyScrollAnimation();

  const sectionHeaderRef = useScrollAnimation();

  const fullScreenSliderRef = useScrollAnimation();

  const animatedTagCopyRef = useScrollAnimation();

  const lightSeparatorFirst = useScrollAnimation();

  const lightSeparatorSecond = useScrollAnimation();

  const lightSeparatorThird = useScrollAnimation();

  const darkSeparatorFirst = useScrollAnimation();

  const darkSeparatorSecond = useScrollAnimation();

  useEffect(() => {
    setCurrentPage('Case Study');
  }, []);

  if (!caseStudy) {
    return <NotFoundPage />;
  }

  const {
    hero: { title, heroDescriptionText, bgImg, projectSection },
    pageDescription,
    externalLink,
  } = caseStudy || {};

  return (
    <main className="page page--case-study">
      <CaseStudyBanner
        pageDescription={pageDescription}
        descriptionText={heroDescriptionText}
        title={title}
        bgImg={bgImg}
        projectSection={projectSection}
        paralaxTranslateY={[20, -20]}
        externalLink={externalLink}
      />
      <div className="case-study-content">
        <div ref={projectWhiteSectionRef} className="project-white-section">
          <div className="flex flex-col gap-20 bg-white py-20 lg:gap-36 lg:py-36">
            <TitleCopy
              ref={titleCopyFirst}
              title="The Client"
              description="Founded in 2007, Anthos Capital is a prominent venture capital firm managing over $1.5 billion in investments across emerging consumer and technology companies. With a team of around 50 employees, Anthos is dedicated to supporting innovative entrepreneurs and driving the future of technology and consumer trends from their base in Santa Monica, California."
              className={ANIMATED_CLASSNAME}
            />
            <Separator
              ref={lightSeparatorFirst}
              className={cn('mx-auto w-[95%] max-w-7xl', ANIMATED_CLASSNAME)}
            />
            <CopyCentered
              ref={copyCenteredRef}
              tag="GOAL"
              copy="Anthos Capital's primary goal is to become a 100% data-driven venture capital firm within the next 5 years by building a comprehensive technology ecosystem."
              description="They aimed to integrate GenAI and automation tools to transform vast amounts of data into actionable insights, ultimately positioning themselves as a leader in the venture capital industry while maintaining efficiency with a lean team."
              className={ANIMATED_CLASSNAME}
            />
            <Separator
              ref={lightSeparatorSecond}
              className={cn('mx-auto w-[95%] max-w-7xl', ANIMATED_CLASSNAME)}
            />
            <TitleCopy
              ref={titleCopySecond}
              title="The Challenge"
              description="The primary challenge for Anthos Capital and Loop3 was executing this ambitious transformation with a small, dedicated team. The team had to navigate a vast array of technologies, including GenAI, front-end and back-end development, integrations, and infrastructure. Balancing this complexity with the need for speed and precision required exceptional flexibility and expertise, all while ensuring the project stayed on track and met Anthos's high standards."
              className={ANIMATED_CLASSNAME}
            />
            <ImageGallery
              ref={imageGalleryFirst}
              className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
              images={[
                { src: '/images/case-studies/anthoscapital/anthos-gallery-1.webp', alt: 'image1' },
                { src: '/images/case-studies/anthoscapital/anthos-gallery-2.webp', alt: 'image2' },
              ]}
            />
            <SectionHeader
              title={'The Process'}
              ref={sectionHeaderRef}
              description="Our approach at Loop3 is centered on generating solutions that leverage technology in the most efficient way possible. Whether it's building new tools or integrating existing services like Slack, Affinity, and PitchBook, our goal is to streamline workflows and ensure our solutions fit seamlessly into Anthos Capital's day-to-day operations. We adapt our technology to their workflow, not the other way around. Below is an outline of the process we follow, from ideation to iteration, ensuring each product we create delivers maximum value while integrating effortlessly into their ecosystem."
              className={cn('mx-auto px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
            />
            <LeftRightCopy
              ref={leftRightCopyFirst}
              titleRef={leftRightCopyFirstTitle}
              contentSide="left"
              title="Ideation"
              copy="Our process begins when the Anthos team comes to us with a challenge, an idea, or a task that would improve their day-to-day operations. Whether it's solving a problem or enhancing an existing workflow, their requests are always geared toward making their operations smoother and more data-driven. Once we understand the challenge, we brainstorm the best way to leverage technology, exploring various approaches like GenAI, automation, or custom development to craft the most effective solution that aligns with Anthos's goals."
              images={[
                { src: '/images/case-studies/anthoscapital/anthos-lrc-1.webp', alt: 'image1' },
                { src: '/images/case-studies/anthoscapital/anthos-lrc-2.webp', alt: 'image2' },
                { src: '/images/case-studies/anthoscapital/anthos-lrc-3.webp', alt: 'image3' },
              ]}
            />
            <LeftRightCopy
              ref={leftRightCopySecond}
              titleRef={leftRightCopySecondTitle}
              title="Crafting a Solution"
              copy="With a plan in place, we quickly move to build an MVP (Minimum Viable Product) that directly tackles the problem at hand. Speed is key at this stage, so we focus on delivering a functional solution that can be tested and refined as soon as possible. This allows us to gather early feedback from the Anthos team."
              images={[
                { src: '/images/case-studies/anthoscapital/anthos-lrc-2.webp', alt: 'image2' },
                { src: '/images/case-studies/anthoscapital/anthos-lrc-3.webp', alt: 'image3' },
                { src: '/images/case-studies/anthoscapital/anthos-lrc-1.webp', alt: 'image1' },
              ]}
            />
            <LeftRightCopy
              ref={leftRightCopyThird}
              titleRef={leftRightCopyThirdTitle}
              contentSide="left"
              title="Iteration"
              copy="Based on feedback from the MVP, we iterate and improve the product, ensuring it adds increasing value with each iteration. As we refine the solution, we expand its functionality and fine-tune its performance, aligning with Anthos evolving needs and goals."
              images={[
                { src: '/images/case-studies/anthoscapital/anthos-lrc-3.webp', alt: 'image3' },
                { src: '/images/case-studies/anthoscapital/anthos-lrc-2.webp', alt: 'image2' },
                { src: '/images/case-studies/anthoscapital/anthos-lrc-1.webp', alt: 'image1' },
              ]}
            />
            <LeftRightCopy
              ref={leftRightCopyFourth}
              titleRef={leftRightCopyFourthTitle}
              title="Repeat"
              copy="As we refine and scale each product, we're also working on new solutions. Our team handles multiple products at once, ensuring that while we maintain and improve existing tools, we're always developing new ones to meet Anthos's evolving needs. This approach allows us to continuously expand their technology ecosystem, creating a seamless flow of innovation and improvement."
            />
            <Separator
              ref={lightSeparatorThird}
              className={cn('mx-auto max-w-7xl w-[95%] lg:-mb-36', ANIMATED_CLASSNAME)}
            />
          </div>
        </div>
        <div className="animated-copy animate-transition flex flex-col gap-36 bg-loop-black py-36">
          <TechnologiesList
            ref={technologiesListRef}
            icons={[
              <ReactLogo key="react" />,
              <NodeJsLogo key="nodejs" />,
              <TypescriptLogo key="typescript" />,
            ]}
            description="For White Canvas's QA automation tool, we selected a tech stack that prioritizes performance, flexibility, and scalability. React provided a dynamic, user-friendly front end, while Node.js powered the backend with efficiency and speed. Leveraging AWS ensured robust, scalable cloud infrastructure that could handle the demands of extensive testing. This combination allowed us to deliver a high-performance, reliable solution that seamlessly integrates into their daily operations, supporting long-term growth and success."
            className={{
              section: ANIMATED_CLASSNAME,
              text: 'animate-transition-text text-white',
            }}
          />
          <div className="block">
            <AnimatedTagCopy
              ref={animatedTagCopyRef}
              tag="the result"
              className={{
                section: cn(ANIMATED_CLASSNAME, 'mx-auto px-4 md:px-8 xl:px-0'),
                tag: 'animate-transition-text',
              }}
              animatedCopy={{
                id: 'animated-copy-1',
                text: "<w>/<w>The <w>QA automation<w> tool has become a key part of White Canvas's daily operations, <w>saving significant time<w> on manual testing. Its intuitive design allows the team to catch and report bugs before they reach production, improving overall <w>project quality.<w> This tool has proven to be an indispensable asset, driving efficiency and enhancing outcomes.",
                trigger: '.animated-copy',
                animationStart: 'top 30%',
                className: 'mx-auto',
              }}
            />
          </div>
          <FullScreenSlider
            ref={fullScreenSliderRef}
            className={cn('mx-auto px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
            tag="the product"
            images={[
              { src: '/images/case-studies/anthoscapital/anthos-carousel-1.webp', alt: 'image1' },
              { src: '/images/case-studies/anthoscapital/anthos-lrc-3.webp', alt: 'image3' },
              { src: '/images/case-studies/anthoscapital/anthos-lrc-2.webp', alt: 'image2' },
              { src: '/images/case-studies/anthoscapital/anthos-lrc-1.webp', alt: 'image1' },
            ]}
          />
          <Separator
            ref={darkSeparatorFirst}
            className={cn('mx-auto max-w-7xl w-[95%] bg-gray-dark', ANIMATED_CLASSNAME)}
          />
          <Review
            client={{ name: 'Facundo Gamond', role: 'CTO at White Canvas' }}
            message="“Loop3 was incredibly easy to work with, consistently bringing fresh ideas to the table and enhancing our project with innovative solutions.”"
            logo={<ClutchLogo />}
          />
          <Separator
            ref={darkSeparatorSecond}
            className={cn('mx-auto max-w-7xl w-[95%] bg-gray-dark', ANIMATED_CLASSNAME)}
          />
        </div>
      </div>
    </main>
  );
};

export default AnthosCapital;

export const Head = () => {
  if (caseStudy) {
    return (
      <SEO
        title="Loop3 Studio - Anthos Capital"
        description={`${caseStudy.hero.heroDescriptionText}`}
      />
    );
  }
};
